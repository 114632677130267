import Parse from "parse";

export interface OD3_BDE_ListAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  list: string;
}

export class OD3_BDE_List extends Parse.Object<OD3_BDE_ListAttributes> {
  static className: string = "OD3_BDE_List";

  constructor(data?: Partial<OD3_BDE_ListAttributes>) {
    super("OD3_BDE_List", data as OD3_BDE_ListAttributes);
  }

  get list(): string {
    return super.get("list");
  }
  set list(value: string) {
    super.set("list", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_List", OD3_BDE_List);
