import React from "react";
import { Kuenne_AkkordMaschinenParameter } from "../types";
import { useParseQuery } from "parse-hooks";

import Parse from "parse";

export const useMachineParameters = () => {
  const query = React.useMemo(() => {
    const q = new Parse.Query(Kuenne_AkkordMaschinenParameter);

    return q;
  }, []);
  return useParseQuery(query);
};
