import Parse from "parse";

import type { Tenant } from "./Tenant";
import type { _User } from "./_User";

export interface GroupAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  label: string;
  parentGroup: Parse.Relation<Group, Group>;
  roleIsVisible: boolean;
  roleIsVisibleForTenant: boolean;
  tenant?: Tenant;
  users: Parse.Relation<Group, _User>;
  usersSeeEachOther: boolean;
}

export class Group extends Parse.Object<GroupAttributes> {
  static className: string = "OD3_Group";

  constructor(data?: Partial<GroupAttributes>) {
    super("OD3_Group", data as GroupAttributes);
  }

  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get parentGroup(): Parse.Relation<Group, Group> {
    return super.relation("parentGroup");
  }
  get roleIsVisible(): boolean {
    return super.get("roleIsVisible");
  }
  set roleIsVisible(value: boolean) {
    super.set("roleIsVisible", value);
  }
  get roleIsVisibleForTenant(): boolean {
    return super.get("roleIsVisibleForTenant");
  }
  set roleIsVisibleForTenant(value: boolean) {
    super.set("roleIsVisibleForTenant", value);
  }
  get tenant(): Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: Tenant | undefined) {
    super.set("tenant", value);
  }
  get users(): Parse.Relation<Group, _User> {
    return super.relation("users");
  }
  get usersSeeEachOther(): boolean {
    return super.get("usersSeeEachOther");
  }
  set usersSeeEachOther(value: boolean) {
    super.set("usersSeeEachOther", value);
  }
}

Parse.Object.registerSubclass("OD3_Group", Group);
