import Parse from "parse";

type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Monitoring_DataHierachiesAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  hierarchies: any[];
  name: string;
  source: OD3_Source;
  tenant: OD3_Tenant;
}

export class OD3_Monitoring_DataHierachies extends Parse.Object<OD3_Monitoring_DataHierachiesAttributes> {
  static className: string = "OD3_Monitoring_DataHierachies";

  constructor(data?: Partial<OD3_Monitoring_DataHierachiesAttributes>) {
    super("OD3_Monitoring_DataHierachies", data as OD3_Monitoring_DataHierachiesAttributes);
  }

  get hierarchies(): any[] {
    return super.get("hierarchies");
  }
  set hierarchies(value: any[]) {
    super.set("hierarchies", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get source(): OD3_Source {
    return super.get("source");
  }
  set source(value: OD3_Source) {
    super.set("source", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Monitoring_DataHierachies", OD3_Monitoring_DataHierachies);
