import Parse from "parse";

import type { Tenant } from "./Tenant";

export interface VirtualKPIAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  pipe: any;
  template: any;
  tenant?: Tenant;
}

export class VirtualKPI extends Parse.Object<VirtualKPIAttributes> {
  static className: string = "OD3_VirtualKPI";

  constructor(data?: Partial<VirtualKPIAttributes>) {
    super("OD3_VirtualKPI", data as VirtualKPIAttributes);
  }

  get pipe(): any {
    return super.get("pipe");
  }
  set pipe(value: any) {
    super.set("pipe", value);
  }
  get template(): any {
    return super.get("template");
  }
  set template(value: any) {
    super.set("template", value);
  }
  get tenant(): Tenant | undefined {
    return super.get("tenant");
  }
  set tenant(value: Tenant | undefined) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_VirtualKPI", VirtualKPI);
