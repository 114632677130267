import Parse from "parse";

export interface Kuenne_QATestAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  AENBEN?: string;
  AENDAT?: string;
  AENDATETIME?: Date;
  AENUHR?: string;
  ANZAUFM?: number;
  AUFNR?: string;
  BENID?: string;
  BESTEX?: string;
  BEZ?: string;
  CHARGE?: any;
  CHGNR: string;
  DATUM?: string;
  DATUMDATETIME?: Date;
  DEBARTNR?: string;
  DEBNR?: string;
  FREIPRF?: string;
  IDNR?: string;
  LFDNR?: number;
  MASNR?: string;
  NAME?: string;
  NEUBEN?: string;
  NEUDAT?: string;
  NEUDATETIME: Date;
  NEUUHR?: string;
  ORT?: string;
  POSNR?: number;
  PRUEFERGEBNIS?: any;
  RINGNR?: string;
  SONSTIGES?: any;
  STATUS?: string;
}

export class Kuenne_QATest extends Parse.Object<Kuenne_QATestAttributes> {
  static className: string = "Kuenne_QATest";

  constructor(data?: Partial<Kuenne_QATestAttributes>) {
    super("Kuenne_QATest", data as Kuenne_QATestAttributes);
  }

  get AENBEN(): string | undefined {
    return super.get("AENBEN");
  }
  set AENBEN(value: string | undefined) {
    super.set("AENBEN", value);
  }
  get AENDAT(): string | undefined {
    return super.get("AENDAT");
  }
  set AENDAT(value: string | undefined) {
    super.set("AENDAT", value);
  }
  get AENDATETIME(): Date | undefined {
    return super.get("AENDATETIME");
  }
  set AENDATETIME(value: Date | undefined) {
    super.set("AENDATETIME", value);
  }
  get AENUHR(): string | undefined {
    return super.get("AENUHR");
  }
  set AENUHR(value: string | undefined) {
    super.set("AENUHR", value);
  }
  get ANZAUFM(): number | undefined {
    return super.get("ANZAUFM");
  }
  set ANZAUFM(value: number | undefined) {
    super.set("ANZAUFM", value);
  }
  get AUFNR(): string | undefined {
    return super.get("AUFNR");
  }
  set AUFNR(value: string | undefined) {
    super.set("AUFNR", value);
  }
  get BENID(): string | undefined {
    return super.get("BENID");
  }
  set BENID(value: string | undefined) {
    super.set("BENID", value);
  }
  get BESTEX(): string | undefined {
    return super.get("BESTEX");
  }
  set BESTEX(value: string | undefined) {
    super.set("BESTEX", value);
  }
  get BEZ(): string | undefined {
    return super.get("BEZ");
  }
  set BEZ(value: string | undefined) {
    super.set("BEZ", value);
  }
  get CHARGE(): any | undefined {
    return super.get("CHARGE");
  }
  set CHARGE(value: any | undefined) {
    super.set("CHARGE", value);
  }
  get CHGNR(): string {
    return super.get("CHGNR");
  }
  set CHGNR(value: string) {
    super.set("CHGNR", value);
  }
  get DATUM(): string | undefined {
    return super.get("DATUM");
  }
  set DATUM(value: string | undefined) {
    super.set("DATUM", value);
  }
  get DATUMDATETIME(): Date | undefined {
    return super.get("DATUMDATETIME");
  }
  set DATUMDATETIME(value: Date | undefined) {
    super.set("DATUMDATETIME", value);
  }
  get DEBARTNR(): string | undefined {
    return super.get("DEBARTNR");
  }
  set DEBARTNR(value: string | undefined) {
    super.set("DEBARTNR", value);
  }
  get DEBNR(): string | undefined {
    return super.get("DEBNR");
  }
  set DEBNR(value: string | undefined) {
    super.set("DEBNR", value);
  }
  get FREIPRF(): string | undefined {
    return super.get("FREIPRF");
  }
  set FREIPRF(value: string | undefined) {
    super.set("FREIPRF", value);
  }
  get IDNR(): string | undefined {
    return super.get("IDNR");
  }
  set IDNR(value: string | undefined) {
    super.set("IDNR", value);
  }
  get LFDNR(): number | undefined {
    return super.get("LFDNR");
  }
  set LFDNR(value: number | undefined) {
    super.set("LFDNR", value);
  }
  get MASNR(): string | undefined {
    return super.get("MASNR");
  }
  set MASNR(value: string | undefined) {
    super.set("MASNR", value);
  }
  get NAME(): string | undefined {
    return super.get("NAME");
  }
  set NAME(value: string | undefined) {
    super.set("NAME", value);
  }
  get NEUBEN(): string | undefined {
    return super.get("NEUBEN");
  }
  set NEUBEN(value: string | undefined) {
    super.set("NEUBEN", value);
  }
  get NEUDAT(): string | undefined {
    return super.get("NEUDAT");
  }
  set NEUDAT(value: string | undefined) {
    super.set("NEUDAT", value);
  }
  get NEUDATETIME(): Date {
    return super.get("NEUDATETIME");
  }
  set NEUDATETIME(value: Date) {
    super.set("NEUDATETIME", value);
  }
  get NEUUHR(): string | undefined {
    return super.get("NEUUHR");
  }
  set NEUUHR(value: string | undefined) {
    super.set("NEUUHR", value);
  }
  get ORT(): string | undefined {
    return super.get("ORT");
  }
  set ORT(value: string | undefined) {
    super.set("ORT", value);
  }
  get POSNR(): number | undefined {
    return super.get("POSNR");
  }
  set POSNR(value: number | undefined) {
    super.set("POSNR", value);
  }
  get PRUEFERGEBNIS(): any | undefined {
    return super.get("PRUEFERGEBNIS");
  }
  set PRUEFERGEBNIS(value: any | undefined) {
    super.set("PRUEFERGEBNIS", value);
  }
  get RINGNR(): string | undefined {
    return super.get("RINGNR");
  }
  set RINGNR(value: string | undefined) {
    super.set("RINGNR", value);
  }
  get SONSTIGES(): any | undefined {
    return super.get("SONSTIGES");
  }
  set SONSTIGES(value: any | undefined) {
    super.set("SONSTIGES", value);
  }
  get STATUS(): string | undefined {
    return super.get("STATUS");
  }
  set STATUS(value: string | undefined) {
    super.set("STATUS", value);
  }
}

Parse.Object.registerSubclass("Kuenne_QATest", Kuenne_QATest);
