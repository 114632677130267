import React from "react";
import {
  _User,
  _UserAttributes,
  Kuenne_AkkordEintraege,
  Kuenne_QATest,
  OD3_Source,
} from "../types";
import { useParseQuery } from "parse-hooks";

import Parse from "parse";

export const useAkkordEntries = (month?: Date, worker?: Parse.User<_User>) => {
  console.log({ month, worker });
  const query = React.useMemo(() => {
    const q = new Parse.Query(Kuenne_AkkordEintraege);
    if (month) {
      const qyear = month.getFullYear();
      const qmonth = month.getMonth() + 1;
      q.equalTo("jahr", qyear);
      q.equalTo("monat", qmonth);
    }
    if (worker) {
      // @ts-ignore
      q.equalTo("werker", worker);
    }
    return q;
  }, [month, worker]);
  return useParseQuery(query);
};
