import {
  AdminLayout,
  useOpenDashServices,
  useTranslation,
} from "@opendash/core";
import dayjs from "dayjs";
import * as React from "react";
import { DashboardInterface, useDashboards } from "@opendash/plugin-monitoring";
import { Kuenne_QATest, Kuenne_QATestAttributes } from "../types";
import Parse, { JSONBaseAttributes } from "parse";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  List,
  Row,
  Spin,
  Table,
  Tabs,
  Tag,
} from "antd";
import { AdminToolbar, Description } from "@opendash/ui";
import { useDebounce } from "@opendash/core";
import { useDataService } from "@opendash/plugin-timeseries";
import DescriptionsItem from "antd/es/descriptions/Item";
import { Icon } from "@opendash/icons";
import { stringToColor } from "@opendash/core";
export default () => {
  const t = useTranslation();
  const DataService = useDataService();
  const [orders, setOrders] = React.useState<Kuenne_QATest[]>([]);
  const [allOrders, setAllOrders] = React.useState<Kuenne_QATest[]>([]);
  const [searchString, setSearchString] = React.useState("");
  const searchStringDebounced = useDebounce(searchString, 1000);
  const dashboards = useDashboards();
  const { SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const [cOrder, setCOrder] = React.useState<Kuenne_QATest | null>(null);
  const [successorsStart, setSuccessorsStart] = React.useState<
    Record<string, Date>
  >({});
  const [orderOnMachine, setOrderOnMachine] = React.useState<
    Record<string, Kuenne_QATest[]>
  >({});
  const overViewBlacklist = [
    "AENDAT",
    "AENUHR",
    "BENID",
    "DATUM",
    "NEUBEN",
    "NEUDAT",
    "NEUUHR",
    "createdAt",
    "updatedAt",
  ];

  const blacklistPruef = ["PH"];
  const [orderRing, setOrderRings] = React.useState<Record<
    string,
    { ring: string; neu: Date; aen: Date }[]
  > | null>(null);
  const [dataDrawer, setDataDrawer] = React.useState<{
    data: Kuenne_QATest;
    label: string;
    datakey: string;
    blacklist: string[];
  } | null>(null);
  const [availableRings, setAvailableRings] = React.useState<Kuenne_QATest[]>(
    []
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    init();
  }, [searchStringDebounced]);

  React.useEffect(() => {
    if (!allOrders) return;
    //Rings
    const rings = {} as Record<
      string,
      { ring: string; neu: Date; aen: Date }[]
    >;
    allOrders.forEach((order) => {
      const key = "" + order.AUFNR + "-" + order.POSNR;
      if (!(key in rings)) {
        rings[key] = [
          {
            ring: order.RINGNR || "",
            neu: order.NEUDATETIME,
            aen: order.AENDATETIME!,
          },
        ];
      } else {
        rings[key].push({
          ring: order.RINGNR || "",
          neu: order.NEUDATETIME,
          aen: order.AENDATETIME!,
        });
      }
    });
    Object.keys(rings).forEach((key) => {
      rings[key] = rings[key].sort((a, b) => {
        return a.neu.getTime() - b.neu.getTime();
      });
    });
    setOrderRings(rings);

    // Machines
    const machineOrders: Record<string, Kuenne_QATest[]> = {};
    for (let cOrder of allOrders) {
      if (!machineOrders["MASNR:" + cOrder.MASNR]) {
        machineOrders["MASNR:" + cOrder.MASNR] = [] as Kuenne_QATest[];
      }
      machineOrders["MASNR:" + cOrder.MASNR].push(cOrder);
    }
    Object.keys(machineOrders).forEach((key) => {
      machineOrders[key] = machineOrders[key].sort((a, b) => {
        return a.AENDATETIME!.getTime() - b.AENDATETIME!.getTime();
      });
    });
    setOrderOnMachine(machineOrders);
  }, [allOrders]);

  const init = async () => {
    let query = new Parse.Query(Kuenne_QATest);
    if (searchString !== "") {
      query.contains("AUFNR", searchString);
      query = Parse.Query.or(
        query,
        new Parse.Query(Kuenne_QATest).equalTo("AUFNR", searchString),
        new Parse.Query(Kuenne_QATest).contains("NAME", searchString),
        new Parse.Query(Kuenne_QATest).contains("BEZ", searchString)
      );
    }
    query.descending("NEUDATETIME");
    query = query.limit(1000);
    const ordersResult = await query.find();

    setAllOrders(ordersResult);
    const reverseAll = [...ordersResult].reverse();
    const currentOrders = {} as Record<string, Kuenne_QATest>;

    for (let i = 0; i < reverseAll.length; i++) {
      const order = reverseAll[i];
      if (
        !currentOrders[order.MASNR + "-" + order.AUFNR + "-" + order.POSNR] ||
        currentOrders[
          order.MASNR! + "-" + order.AUFNR! + "-" + order.POSNR!
        ].AENDATETIME!.getTime() < order.AENDATETIME!.getTime()
      ) {
        currentOrders[order.MASNR + "-" + order.AUFNR + "-" + order.POSNR] =
          order;
      }
    }

    // setOrders(
    //   ordersResult.filter((order, index, all) => {
    //     return (
    //       index === 0 ||
    //       !(
    //         order.AUFNR === all[index - 1].AUFNR &&
    //         order.POSNR === all[index - 1].POSNR
    //       )
    //     );
    //   })
    // );
    setOrders(
      Object.values(currentOrders).sort((a, b) => {
        return -1 * (a.AENDATETIME!.getTime() - b.AENDATETIME!.getTime());
      })
    );
  };
  React.useEffect(() => {
    if (orders && orders.length > 0) {
      getOrderEnds(orders);
    }
  }, [orders]);
  const getOrderEnds = async (orders: Kuenne_QATest[]) => {
    const ends = {} as Record<string, Date>;

    const queries = orders.map((order) => {
      const querfollowingQuery1 = new Parse.Query(Kuenne_QATest).notEqualTo(
        "AUFNR",
        order.AUFNR as string
      );

      const followingQuery2 = new Parse.Query(Kuenne_QATest).notEqualTo(
        "POSNR",
        order.POSNR as number
      );
      const followingOrder = Parse.Query.or(
        querfollowingQuery1,
        followingQuery2
      )
        .equalTo("MASNR", order.MASNR)
        .greaterThan("NEUDATETIME", order.NEUDATETIME)
        .addAscending("NEUDATETIME");
      return followingOrder.first();

      /*
      return {
        method: "POST",
        path: "/parse/classes/Kuenne_QATest",
        body: followingOrder.toJSON(),
      };
      */
    });

    const res = await Promise.all(queries);

    for (const i in orders) {
      ends[orders[i].id] = res[i]?.NEUDATETIME || new Date(0);
    }
    setSuccessorsStart(ends);
  };

  const switchToDashboardForOrder = async (
    dashboard: DashboardInterface,
    order: Kuenne_QATest
  ) => {
    if (
      orderRing == null ||
      orderRing[order.AUFNR + "-" + order.POSNR].length === 0
    )
      return;
    /*
      const querfollowingQuery1 = new Parse.Query(Kuenne_QATest).notEqualTo(
      "AUFNR",
      order.AUFNR as string
    );

    const followingQuery2 = new Parse.Query(Kuenne_QATest).notEqualTo(
      "POSNR",
      order.POSNR as number
    );
    const followingOrder = await Parse.Query.or(
      querfollowingQuery1,
      followingQuery2
    )
      .equalTo("MASNR", order.MASNR)
      .greaterThan("NEUDATETIME", order.NEUDATETIME)
      .addAscending("NEUDATETIME")
      .first();
*/
    const followingOrder =
      successorsStart[order.id].getTime() === 0
        ? new Date()
        : successorsStart[order.id];

    // DataService.store.update((draft) => {
    //   const endTime = !followingOrder
    //     ? new Date().getTime()
    //     : followingOrder.getTime();

    //   draft.overwriteFetchingOptions = {
    //     historyType: "absolute",
    //     start: orderRing[order.AUFNR + "-" + order.POSNR][0].neu.getTime(),
    //     end: endTime,
    //     live: successorsStart[order.id].getTime() === 0,
    //   };
    // });
    SourceService.setCurrent(dashboard.source);
    setTimeout(() => {
      const endTime = !followingOrder
        ? new Date().getTime()
        : followingOrder.getTime();
      const options = {
        historyType: "absolute",
        start: orderRing[order.AUFNR + "-" + order.POSNR][0].neu.getTime(),
        end: endTime,
        live: successorsStart[order.id].getTime() === 0,
      };
      const optionsString = JSON.stringify(options);
      window.open(
        `/monitoring/dashboards/${dashboard.source}/${dashboard.id}?timeOverwrite=${optionsString}`,
        "_blank"
      );
    }, 200);
  };

  const columnItems = [
    "AUFNR",
    "POSNR",
    "MASNR",
    "NAME",
    {
      title: t("kuenne:order.ENDTIME"),
      dataIndex: "endtime",
      key: "endtime",
      render: (text: string, record: Kuenne_QATest, index: number) => {
        if (!successorsStart[record.id]) return <Spin />;
        return (
          <>
            {successorsStart[record.id].getTime() !== 0 && (
              <p>
                {dayjs(successorsStart[record.id].getTime()).format(
                  "HH:mm:ss @ DD.MM.YYYY"
                )}
              </p>
            )}
            {successorsStart[record.id].getTime() === 0 && (
              <Tag icon={<Icon icon="fa:play"></Icon>}>
                {t("kuenne:order.running")}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("kuenne:order.NEUDATETIME"),
      dataIndex: "NEUDATETIME",
      key: "NEUDATETIME",
      render: (text: string, record: Kuenne_QATest, index: number) => {
        const neu =
          orderRing?.["" + record.AUFNR + "-" + record.POSNR]?.[0].neu ||
          record.NEUDATETIME;
        return dayjs(neu).format("HH:mm:ss @ DD.MM.YYYY");
      },
    },
  ] as string[];
  const columns = columnItems.map((item) => {
    if ((item as string | any) instanceof Object) return item;
    const translationstring = "kuenne:order." + item;
    return {
      title: t(translationstring),
      dataIndex: item,
      key: item,
    };
  });

  const mapMachine = (maschine: string) => {
    if (maschine === "mstWork") return maschine;
    return "kuenne_werk1_M" + maschine;
    //return "mstWork";
  };
  return (
    <div style={{ margin: 16 }}>
      <AdminToolbar
        title={t("kuenne:order.label")}
        search=""
        onSearch={(e) => {
          setSearchString(e);
        }}
      ></AdminToolbar>
      <div
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Table
              columns={
                columns as { title: string; dataIndex: string; key: string }[]
              }
              dataSource={orders}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setCOrder(record);
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            ></Table>
          </Col>
        </Row>
        <Divider />
        {cOrder && (
          <Row style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Col span={11} md={11} xs={24}>
              <Descriptions
                title={t("kuenne:order.overview")}
                bordered
                column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                {Object.keys(cOrder.attributes)
                  .sort()
                  .map((key) => {
                    if (overViewBlacklist.indexOf(key) !== -1) return <></>;
                    if (key === "RINGNR") {
                      return (
                        <DescriptionsItem
                          label={t("kuenne:order." + key)}
                          span={1}
                        >
                          {orderRing &&
                            orderRing[cOrder.AUFNR + "-" + cOrder.POSNR] &&
                            orderRing[cOrder.AUFNR + "-" + cOrder.POSNR]
                              .sort((a, b) => {
                                return (
                                  parseInt(a.ring?.split("-")[1] || "0") -
                                  parseInt(b.ring?.split("-")[1] || "0")
                                );
                              })
                              .map((order) => {
                                return <Tag>{order.ring}</Tag>;
                              })}
                        </DescriptionsItem>
                      );
                    }
                    return (
                      <DescriptionsItem
                        label={t("kuenne:order." + key)}
                        span={1}
                      >
                        {
                          <>
                            {typeof cOrder.get(
                              key as keyof Kuenne_QATestAttributes
                            ) === "object" &&
                              !(
                                cOrder.get(
                                  key as keyof Kuenne_QATestAttributes
                                ) instanceof Date
                              ) && (
                                <Button
                                  type="link"
                                  onClick={() => {
                                    setDataDrawer({
                                      data: cOrder,
                                      datakey: key,
                                      label: key,
                                      blacklist: blacklistPruef,
                                    });
                                  }}
                                >
                                  {t(
                                    "opendash:monitoring.data_sidebar.details"
                                  )}
                                </Button>
                              )}
                            {cOrder.get(
                              key as keyof Kuenne_QATestAttributes
                            ) instanceof Date &&
                              (
                                cOrder[
                                  key as keyof Omit<
                                    Kuenne_QATestAttributes,
                                    "objectId"
                                  >
                                ] as Date
                              ).toDateString()}

                            {!(
                              typeof cOrder.get(
                                key as keyof Kuenne_QATestAttributes
                              ) === "object"
                            ) &&
                              !(
                                cOrder.get(
                                  key as keyof Kuenne_QATestAttributes
                                ) instanceof Date
                              ) &&
                              cOrder[
                                key as keyof Omit<
                                  Kuenne_QATestAttributes,
                                  "objectId"
                                >
                              ]}
                          </>
                        }
                      </DescriptionsItem>
                    );
                  })}
              </Descriptions>
            </Col>
            <Col span={12} md={12} xs={24} style={{ paddingLeft: 16 }}>
              <div style={{ marginBottom: 20 }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: " 16px",
                    lineHeight: 1.5,
                  }}
                >
                  {t("kuenne:order.dashboards")}
                </div>
              </div>
              {!cOrder.MASNR && (
                <Empty
                  description={<span>{t("kuenne:order.noMachine")}</span>}
                />
              )}
              {cOrder.MASNR && (
                <List
                  itemLayout="horizontal"
                  dataSource={dashboards.filter((dashboard) => {
                    const source = sources.find(
                      (source) => source.id === dashboard.source
                    );
                    return mapMachine(cOrder.MASNR!) === source?.tag;
                  })}
                  renderItem={(dashboard) => (
                    <List.Item
                      onClick={() => {
                        switchToDashboardForOrder(dashboard, cOrder);
                      }}
                      actions={[
                        <a
                          key={"link" + dashboard.id}
                          onClick={() => {
                            switchToDashboardForOrder(dashboard, cOrder);
                          }}
                        >
                          <Icon icon="fa:chevron-right" />
                        </a>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: stringToColor(dashboard.name),
                            }}
                          >
                            {dashboard.name.substring(0, 2).toLocaleUpperCase()}
                          </Avatar>
                        }
                        title={dashboard.name}
                        description={
                          sources.find(
                            (cSource) => cSource.id === dashboard.source
                          )?.name || "?"
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </Col>

            {!!dataDrawer && (
              <Drawer
                onClose={() => setDataDrawer(null)}
                placement="bottom"
                open={!!dataDrawer}
                title={t("kuenne:order." + dataDrawer.label)}
                height={"60%"}
              >
                <Tabs>
                  {allOrders
                    .filter(
                      (order) =>
                        order.AUFNR === dataDrawer.data.AUFNR &&
                        order.POSNR === dataDrawer.data.POSNR
                    )
                    .sort((a, b) => {
                      return (
                        parseInt(a.RINGNR?.split("-")[1] || "0") -
                        parseInt(b.RINGNR?.split("-")[1] || "0")
                      );
                    })
                    .map((order) => {
                      return (
                        <Tabs.TabPane tab={order.RINGNR} key={order.RINGNR}>
                          <Descriptions>
                            {Object.keys(
                              order[dataDrawer.datakey as keyof Kuenne_QATest]
                            )
                              .sort()
                              .map((key) => {
                                const isBlacklisted = dataDrawer.blacklist.find(
                                  (blackKey) => {
                                    return key.startsWith(blackKey);
                                  }
                                );
                                if (isBlacklisted) return null;
                                let label = t(
                                  `kuenne:order.KEYS_${dataDrawer.datakey}.${key}`
                                );

                                if (dataDrawer.datakey === "CHARGE") {
                                  if (key.indexOf("CHGTX") !== -1) {
                                    return null;
                                  }
                                  if (key.indexOf("CHGWE") > -1) {
                                    const newKey = key.replace(
                                      "CHGWE",
                                      "CHGTX"
                                    );

                                    label =
                                      order[dataDrawer.datakey][newKey as any];
                                  }
                                }

                                return (
                                  <DescriptionsItem label={label}>
                                    {
                                      order[
                                        dataDrawer.datakey as keyof Kuenne_QATest
                                      ][key]
                                    }
                                  </DescriptionsItem>
                                );
                              })}
                          </Descriptions>
                        </Tabs.TabPane>
                      );
                    })}
                </Tabs>
                {/* <Descriptions>
                {Object.keys(dataDrawer.data)
                  .sort()
                  .map((key) => {
                    return (
                      <DescriptionsItem
                        label={t(`kuenne:order.${dataDrawer.label}.${key}`)}
                      >
                        {dataDrawer.data[key]}
                      </DescriptionsItem>
                    );
                  })}
              </Descriptions> */}
              </Drawer>
            )}
          </Row>
        )}
      </div>
    </div>
  );
};
