import Parse from "parse";

type OD3_Source = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Monitoring_ReportImageAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  config: any;
  name: string;
  source: OD3_Source;
  tenant: OD3_Tenant;
  type: string;
}

export class OD3_Monitoring_ReportImage extends Parse.Object<OD3_Monitoring_ReportImageAttributes> {
  static className: string = "OD3_Monitoring_ReportImage";

  constructor(data?: Partial<OD3_Monitoring_ReportImageAttributes>) {
    super("OD3_Monitoring_ReportImage", data as OD3_Monitoring_ReportImageAttributes);
  }

  get config(): any {
    return super.get("config");
  }
  set config(value: any) {
    super.set("config", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get source(): OD3_Source {
    return super.get("source");
  }
  set source(value: OD3_Source) {
    super.set("source", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get type(): string {
    return super.get("type");
  }
  set type(value: string) {
    super.set("type", value);
  }
}

Parse.Object.registerSubclass("OD3_Monitoring_ReportImage", OD3_Monitoring_ReportImage);
