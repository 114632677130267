import Parse from "parse";

export interface AX_EventExchangeAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  auftragsnummer: string;
  charge?: string;
  maschine?: string;
  meldungsart?: string;
  produktionsnummer: string;
  zeitstempel?: Date;
}

export class AX_EventExchange extends Parse.Object<AX_EventExchangeAttributes> {
  static className: string = "AX_EventExchange";

  constructor(data?: Partial<AX_EventExchangeAttributes>) {
    super("AX_EventExchange", data as AX_EventExchangeAttributes);
  }

  get auftragsnummer(): string {
    return super.get("auftragsnummer");
  }
  set auftragsnummer(value: string) {
    super.set("auftragsnummer", value);
  }
  get charge(): string | undefined {
    return super.get("charge");
  }
  set charge(value: string | undefined) {
    super.set("charge", value);
  }
  get maschine(): string | undefined {
    return super.get("maschine");
  }
  set maschine(value: string | undefined) {
    super.set("maschine", value);
  }
  get meldungsart(): string | undefined {
    return super.get("meldungsart");
  }
  set meldungsart(value: string | undefined) {
    super.set("meldungsart", value);
  }
  get produktionsnummer(): string {
    return super.get("produktionsnummer");
  }
  set produktionsnummer(value: string) {
    super.set("produktionsnummer", value);
  }
  get zeitstempel(): Date | undefined {
    return super.get("zeitstempel");
  }
  set zeitstempel(value: Date | undefined) {
    super.set("zeitstempel", value);
  }
}

Parse.Object.registerSubclass("AX_EventExchange", AX_EventExchange);
