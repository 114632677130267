import Parse from "parse";

export interface OD3_SourceMetaAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  kpiData: any[];
  location?: Parse.GeoPoint;
  name?: string;
}

export class OD3_SourceMeta extends Parse.Object<OD3_SourceMetaAttributes> {
  static className: string = "OD3_SourceMeta";

  constructor(data?: Partial<OD3_SourceMetaAttributes>) {
    super("OD3_SourceMeta", data as OD3_SourceMetaAttributes);
  }

  get kpiData(): any[] {
    return super.get("kpiData");
  }
  set kpiData(value: any[]) {
    super.set("kpiData", value);
  }
  get location(): Parse.GeoPoint | undefined {
    return super.get("location");
  }
  set location(value: Parse.GeoPoint | undefined) {
    super.set("location", value);
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
}

Parse.Object.registerSubclass("OD3_SourceMeta", OD3_SourceMeta);
