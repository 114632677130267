import Parse from "parse";

export interface OD3_GTFS_LevelAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  level_index: number;
  level_name?: string;
}

export class OD3_GTFS_Level extends Parse.Object<OD3_GTFS_LevelAttributes> {
  static className: string = "OD3_GTFS_Level";

  constructor(data?: Partial<OD3_GTFS_LevelAttributes>) {
    super("OD3_GTFS_Level", data as OD3_GTFS_LevelAttributes);
  }

  get level_index(): number {
    return super.get("level_index");
  }
  set level_index(value: number) {
    super.set("level_index", value);
  }
  get level_name(): string | undefined {
    return super.get("level_name");
  }
  set level_name(value: string | undefined) {
    super.set("level_name", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Level", OD3_GTFS_Level);
