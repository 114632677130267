import Parse from "parse";

export interface OD3_GTFS_Wheelchair_BoardingAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  condition?: string;
  description?: string;
  value: number;
}

export class OD3_GTFS_Wheelchair_Boarding extends Parse.Object<OD3_GTFS_Wheelchair_BoardingAttributes> {
  static className: string = "OD3_GTFS_Wheelchair_Boarding";

  constructor(data?: Partial<OD3_GTFS_Wheelchair_BoardingAttributes>) {
    super("OD3_GTFS_Wheelchair_Boarding", data as OD3_GTFS_Wheelchair_BoardingAttributes);
  }

  get condition(): string | undefined {
    return super.get("condition");
  }
  set condition(value: string | undefined) {
    super.set("condition", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get value(): number {
    return super.get("value");
  }
  set value(value: number) {
    super.set("value", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Wheelchair_Boarding", OD3_GTFS_Wheelchair_Boarding);
