import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface OD3_TenantMetaAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  tenant: OD3_Tenant;
}

export class OD3_TenantMeta extends Parse.Object<OD3_TenantMetaAttributes> {
  static className: string = "OD3_TenantMeta";

  constructor(data?: Partial<OD3_TenantMetaAttributes>) {
    super("OD3_TenantMeta", data as OD3_TenantMetaAttributes);
  }

  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_TenantMeta", OD3_TenantMeta);
