import Parse from "parse";

export interface OD3_Monitoring_JobsAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  group: string;
  interval: any;
  last_execution_ow_job: number;
  name: string;
  next_execution_ow_job: number;
  options: any;
  type: string;
  user: string;
}

export class OD3_Monitoring_Jobs extends Parse.Object<OD3_Monitoring_JobsAttributes> {
  static className: string = "OD3_Monitoring_Jobs";

  constructor(data?: Partial<OD3_Monitoring_JobsAttributes>) {
    super("OD3_Monitoring_Jobs", data as OD3_Monitoring_JobsAttributes);
  }

  get group(): string {
    return super.get("group");
  }
  set group(value: string) {
    super.set("group", value);
  }
  get interval(): any {
    return super.get("interval");
  }
  set interval(value: any) {
    super.set("interval", value);
  }
  get last_execution_ow_job(): number {
    return super.get("last_execution_ow_job");
  }
  set last_execution_ow_job(value: number) {
    super.set("last_execution_ow_job", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get next_execution_ow_job(): number {
    return super.get("next_execution_ow_job");
  }
  set next_execution_ow_job(value: number) {
    super.set("next_execution_ow_job", value);
  }
  get options(): any {
    return super.get("options");
  }
  set options(value: any) {
    super.set("options", value);
  }
  get type(): string {
    return super.get("type");
  }
  set type(value: string) {
    super.set("type", value);
  }
  get user(): string {
    return super.get("user");
  }
  set user(value: string) {
    super.set("user", value);
  }
}

Parse.Object.registerSubclass("OD3_Monitoring_Jobs", OD3_Monitoring_Jobs);
