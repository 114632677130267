import Parse from "parse";

export interface OD3_GTFS_Location_TypeAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description: string;
  name: string;
  value: number;
}

export class OD3_GTFS_Location_Type extends Parse.Object<OD3_GTFS_Location_TypeAttributes> {
  static className: string = "OD3_GTFS_Location_Type";

  constructor(data?: Partial<OD3_GTFS_Location_TypeAttributes>) {
    super("OD3_GTFS_Location_Type", data as OD3_GTFS_Location_TypeAttributes);
  }

  get description(): string {
    return super.get("description");
  }
  set description(value: string) {
    super.set("description", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get value(): number {
    return super.get("value");
  }
  set value(value: number) {
    super.set("value", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Location_Type", OD3_GTFS_Location_Type);
