import Parse from "parse";

import type { _User } from "./_User";

export interface ReportingHistoryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  owner?: _User;
  params: any;
  source?: string;
  type?: string;
}

export class ReportingHistory extends Parse.Object<ReportingHistoryAttributes> {
  static className: string = "ReportingHistory";

  constructor(data?: Partial<ReportingHistoryAttributes>) {
    super("ReportingHistory", data as ReportingHistoryAttributes);
  }

  get owner(): _User | undefined {
    return super.get("owner");
  }
  set owner(value: _User | undefined) {
    super.set("owner", value);
  }
  get params(): any {
    return super.get("params");
  }
  set params(value: any) {
    super.set("params", value);
  }
  get source(): string | undefined {
    return super.get("source");
  }
  set source(value: string | undefined) {
    super.set("source", value);
  }
  get type(): string | undefined {
    return super.get("type");
  }
  set type(value: string | undefined) {
    super.set("type", value);
  }
}

Parse.Object.registerSubclass("ReportingHistory", ReportingHistory);
