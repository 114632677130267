import Parse from "parse";

type OD3_Source = Parse.Object;
type schwierigkeitsfaktor = { mm: number; schwierigkeit: number };
export interface Kuenne_AkkordMaschinenParameterAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  basis: number;
  maschine: OD3_Source;
  maschinenzusatz: number;
  schwierigkeitsfaktor: schwierigkeitsfaktor[];
  wechselzeit: number;
  lauflaengenSkalierung: number;
}

export class Kuenne_AkkordMaschinenParameter extends Parse.Object<Kuenne_AkkordMaschinenParameterAttributes> {
  static className: string = "Kuenne_AkkordMaschinenParameter";

  constructor(data?: Partial<Kuenne_AkkordMaschinenParameterAttributes>) {
    super(
      "Kuenne_AkkordMaschinenParameter",
      data as Kuenne_AkkordMaschinenParameterAttributes
    );
  }

  get basis(): number {
    return super.get("basis");
  }

  get lauflaengenSkalierung(): number {
    return super.get("lauflaengenSkalierung");
  }
  set lauflaengenSkalierung(val: number) {
    super.set("lauflaengenSkalierung", val);
  }

  set basis(value: number) {
    super.set("basis", value);
  }
  get maschine(): OD3_Source {
    return super.get("maschine");
  }
  set maschine(value: OD3_Source) {
    super.set("maschine", value);
  }
  get maschinenzusatz(): number {
    return super.get("maschinenzusatz");
  }
  set maschinenzusatz(value: number) {
    super.set("maschinenzusatz", value);
  }
  get schwierigkeitsfaktor(): schwierigkeitsfaktor[] {
    return super.get("schwierigkeitsfaktor");
  }
  set schwierigkeitsfaktor(value: schwierigkeitsfaktor[]) {
    super.set("schwierigkeitsfaktor", value);
  }
  get wechselzeit(): number {
    return super.get("wechselzeit");
  }
  set wechselzeit(value: number) {
    super.set("wechselzeit", value);
  }
}

Parse.Object.registerSubclass(
  "Kuenne_AkkordMaschinenParameter",
  Kuenne_AkkordMaschinenParameter
);
