import Parse from "parse";

type OD3_GTFS_Stop = Parse.Object;
type OD3_GTFS_Trip = Parse.Object;

export interface OD3_GTFS_Stop_TimesAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  arrival_time?: Date;
  departure_time?: Date;
  stop_id: OD3_GTFS_Stop;
  stop_sequence: number;
  trip_id: OD3_GTFS_Trip;
}

export class OD3_GTFS_Stop_Times extends Parse.Object<OD3_GTFS_Stop_TimesAttributes> {
  static className: string = "OD3_GTFS_Stop_Times";

  constructor(data?: Partial<OD3_GTFS_Stop_TimesAttributes>) {
    super("OD3_GTFS_Stop_Times", data as OD3_GTFS_Stop_TimesAttributes);
  }

  get arrival_time(): Date | undefined {
    return super.get("arrival_time");
  }
  set arrival_time(value: Date | undefined) {
    super.set("arrival_time", value);
  }
  get departure_time(): Date | undefined {
    return super.get("departure_time");
  }
  set departure_time(value: Date | undefined) {
    super.set("departure_time", value);
  }
  get stop_id(): OD3_GTFS_Stop {
    return super.get("stop_id");
  }
  set stop_id(value: OD3_GTFS_Stop) {
    super.set("stop_id", value);
  }
  get stop_sequence(): number {
    return super.get("stop_sequence");
  }
  set stop_sequence(value: number) {
    super.set("stop_sequence", value);
  }
  get trip_id(): OD3_GTFS_Trip {
    return super.get("trip_id");
  }
  set trip_id(value: OD3_GTFS_Trip) {
    super.set("trip_id", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Stop_Times", OD3_GTFS_Stop_Times);
