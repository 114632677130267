import Parse from "parse";

type OD3_GTFS_Agency = Parse.Object;

export interface OD3_GTFS_RouteAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  agency_id: OD3_GTFS_Agency;
  route_color?: string;
  route_desc?: string;
  route_id: string;
  route_long_name?: string;
  route_short_name?: string;
  route_sort_order?: number;
  route_text_color?: string;
  route_type: number;
  route_url?: string;
}

export class OD3_GTFS_Route extends Parse.Object<OD3_GTFS_RouteAttributes> {
  static className: string = "OD3_GTFS_Route";

  constructor(data?: Partial<OD3_GTFS_RouteAttributes>) {
    super("OD3_GTFS_Route", data as OD3_GTFS_RouteAttributes);
  }

  get agency_id(): OD3_GTFS_Agency {
    return super.get("agency_id");
  }
  set agency_id(value: OD3_GTFS_Agency) {
    super.set("agency_id", value);
  }
  get route_color(): string | undefined {
    return super.get("route_color");
  }
  set route_color(value: string | undefined) {
    super.set("route_color", value);
  }
  get route_desc(): string | undefined {
    return super.get("route_desc");
  }
  set route_desc(value: string | undefined) {
    super.set("route_desc", value);
  }
  get route_id(): string {
    return super.get("route_id");
  }
  set route_id(value: string) {
    super.set("route_id", value);
  }
  get route_long_name(): string | undefined {
    return super.get("route_long_name");
  }
  set route_long_name(value: string | undefined) {
    super.set("route_long_name", value);
  }
  get route_short_name(): string | undefined {
    return super.get("route_short_name");
  }
  set route_short_name(value: string | undefined) {
    super.set("route_short_name", value);
  }
  get route_sort_order(): number | undefined {
    return super.get("route_sort_order");
  }
  set route_sort_order(value: number | undefined) {
    super.set("route_sort_order", value);
  }
  get route_text_color(): string | undefined {
    return super.get("route_text_color");
  }
  set route_text_color(value: string | undefined) {
    super.set("route_text_color", value);
  }
  get route_type(): number {
    return super.get("route_type");
  }
  set route_type(value: number) {
    super.set("route_type", value);
  }
  get route_url(): string | undefined {
    return super.get("route_url");
  }
  set route_url(value: string | undefined) {
    super.set("route_url", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Route", OD3_GTFS_Route);
