import Parse from "parse";

export interface OD3_GTFS_CalendarAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  end_date: Date;
  friday?: boolean;
  monday?: boolean;
  saturday?: boolean;
  start_date: Date;
  sunday?: boolean;
  thursday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
}

export class OD3_GTFS_Calendar extends Parse.Object<OD3_GTFS_CalendarAttributes> {
  static className: string = "OD3_GTFS_Calendar";

  constructor(data?: Partial<OD3_GTFS_CalendarAttributes>) {
    super("OD3_GTFS_Calendar", data as OD3_GTFS_CalendarAttributes);
  }

  get end_date(): Date {
    return super.get("end_date");
  }
  set end_date(value: Date) {
    super.set("end_date", value);
  }
  get friday(): boolean | undefined {
    return super.get("friday");
  }
  set friday(value: boolean | undefined) {
    super.set("friday", value);
  }
  get monday(): boolean | undefined {
    return super.get("monday");
  }
  set monday(value: boolean | undefined) {
    super.set("monday", value);
  }
  get saturday(): boolean | undefined {
    return super.get("saturday");
  }
  set saturday(value: boolean | undefined) {
    super.set("saturday", value);
  }
  get start_date(): Date {
    return super.get("start_date");
  }
  set start_date(value: Date) {
    super.set("start_date", value);
  }
  get sunday(): boolean | undefined {
    return super.get("sunday");
  }
  set sunday(value: boolean | undefined) {
    super.set("sunday", value);
  }
  get thursday(): boolean | undefined {
    return super.get("thursday");
  }
  set thursday(value: boolean | undefined) {
    super.set("thursday", value);
  }
  get tuesday(): boolean | undefined {
    return super.get("tuesday");
  }
  set tuesday(value: boolean | undefined) {
    super.set("tuesday", value);
  }
  get wednesday(): boolean | undefined {
    return super.get("wednesday");
  }
  set wednesday(value: boolean | undefined) {
    super.set("wednesday", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Calendar", OD3_GTFS_Calendar);
