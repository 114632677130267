import Parse from "parse";

type OD3_GTFS_Calendar = Parse.Object;
type OD3_GTFS_Route = Parse.Object;

export interface OD3_GTFS_TripAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  bikes_allowed?: number;
  direction_id?: number;
  route_id: OD3_GTFS_Route;
  service_id: OD3_GTFS_Calendar;
  trip_headsign?: string;
  trip_short_name?: string;
  wheelchair_accessible?: number;
}

export class OD3_GTFS_Trip extends Parse.Object<OD3_GTFS_TripAttributes> {
  static className: string = "OD3_GTFS_Trip";

  constructor(data?: Partial<OD3_GTFS_TripAttributes>) {
    super("OD3_GTFS_Trip", data as OD3_GTFS_TripAttributes);
  }

  get bikes_allowed(): number | undefined {
    return super.get("bikes_allowed");
  }
  set bikes_allowed(value: number | undefined) {
    super.set("bikes_allowed", value);
  }
  get direction_id(): number | undefined {
    return super.get("direction_id");
  }
  set direction_id(value: number | undefined) {
    super.set("direction_id", value);
  }
  get route_id(): OD3_GTFS_Route {
    return super.get("route_id");
  }
  set route_id(value: OD3_GTFS_Route) {
    super.set("route_id", value);
  }
  get service_id(): OD3_GTFS_Calendar {
    return super.get("service_id");
  }
  set service_id(value: OD3_GTFS_Calendar) {
    super.set("service_id", value);
  }
  get trip_headsign(): string | undefined {
    return super.get("trip_headsign");
  }
  set trip_headsign(value: string | undefined) {
    super.set("trip_headsign", value);
  }
  get trip_short_name(): string | undefined {
    return super.get("trip_short_name");
  }
  set trip_short_name(value: string | undefined) {
    super.set("trip_short_name", value);
  }
  get wheelchair_accessible(): number | undefined {
    return super.get("wheelchair_accessible");
  }
  set wheelchair_accessible(value: number | undefined) {
    super.set("wheelchair_accessible", value);
  }
}

Parse.Object.registerSubclass("OD3_GTFS_Trip", OD3_GTFS_Trip);
