import Parse from "parse";

type OD3_Source = Parse.Object;

export interface LocationsAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  name?: string;
  source?: OD3_Source;
  spsid?: string;
}

export class Locations extends Parse.Object<LocationsAttributes> {
  static className: string = "Locations";

  constructor(data?: Partial<LocationsAttributes>) {
    super("Locations", data as LocationsAttributes);
  }

  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get source(): OD3_Source | undefined {
    return super.get("source");
  }
  set source(value: OD3_Source | undefined) {
    super.set("source", value);
  }
  get spsid(): string | undefined {
    return super.get("spsid");
  }
  set spsid(value: string | undefined) {
    super.set("spsid", value);
  }
}

Parse.Object.registerSubclass("Locations", Locations);
