import {
  createComponent,
  makeAutoObservable,
  runInAction,
} from "@opendash/state";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ReadonlyRouteParams,
  RouteStateInterface,
  RouterStatefullHandlerProps,
} from "./types";

export {};

export class RouteContext {
  public loading: boolean = false;
  public title: string | undefined = undefined;
  public description: string | undefined = undefined;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setTitle(title: string | undefined) {
    this.title = title;
  }

  setDescription(description: string | undefined) {
    this.description = description;
  }
}

export function createRouteComponent<
  S extends RouteStateInterface = RouteStateInterface,
>(
  component: React.FunctionComponent<{ state: S }>
): React.ComponentType<{ state: S }> {
  return createComponent<{ state: S }>(component);
}

export const RouterStatefullHandler = React.memo<RouterStatefullHandlerProps>(
  function RouterStatefullHandler({ RouteLayout, RouteState, RouteComponent }) {
    const params = useParams();
    const query = useSearchParams();

    const ctx = React.useMemo(() => new RouteContext(), []);
    const state = React.useMemo(() => new RouteState(ctx), []);

    React.useEffect(() => {
      if (state.$onRouteParams && typeof state.$onRouteParams === "function") {
        state.$onRouteParams(params);
      }
    }, [JSON.stringify(params)]);

    if (RouteLayout) {
      return (
        <RouteLayout ctx={ctx}>
          <RouteComponent state={state} />
        </RouteLayout>
      );
    }

    return <RouteComponent state={state} />;
  }
);

export class TestRouteState implements RouteStateInterface {
  ctx: RouteContext;

  params: ReadonlyRouteParams = {};

  constructor(ctx: RouteContext) {
    this.ctx = ctx;

    this.ctx.setLoading(true);
    this.ctx.setTitle("Dev Route");
    this.ctx.setDescription("Route Description");

    makeAutoObservable(this);

    console.log("TestRouteState.constructor()", ctx);
  }

  async $onRouteParams(params: ReadonlyRouteParams) {
    console.log("TestRouteState.$onRouteParams()", params);

    runInAction(() => {
      this.params = params;
    });

    this.ctx.setLoading(true);
  }
}

export const TestRoute = createRouteComponent<TestRouteState>(({ state }) => {
  console.log("TestRoute render", state);

  const navigate = useNavigate();

  return (
    <div>
      <div>
        Params:
        <br />
        <pre>{JSON.stringify(state.params, null, 2)}</pre>
      </div>

      <div>
        <button
          children="/admin/debug/route/test/a"
          onClick={() => {
            navigate("/admin/debug/route/test/a");
          }}
        />
        <button
          children="/admin/debug/route/test/b"
          onClick={() => {
            navigate("/admin/debug/route/test/b");
          }}
        />
      </div>
    </div>
  );
});
