import Parse from "parse";

type OD3_Knowledge_Category = Parse.Object;
type OD3_Language = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Knowledge_DocumentAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  category: OD3_Knowledge_Category;
  document: Parse.File;
  label: string;
  language: OD3_Language;
  tenant: OD3_Tenant;
}

export class OD3_Knowledge_Document extends Parse.Object<OD3_Knowledge_DocumentAttributes> {
  static className: string = "OD3_Knowledge_Document";

  constructor(data?: Partial<OD3_Knowledge_DocumentAttributes>) {
    super("OD3_Knowledge_Document", data as OD3_Knowledge_DocumentAttributes);
  }

  get category(): OD3_Knowledge_Category {
    return super.get("category");
  }
  set category(value: OD3_Knowledge_Category) {
    super.set("category", value);
  }
  get document(): Parse.File {
    return super.get("document");
  }
  set document(value: Parse.File) {
    super.set("document", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get language(): OD3_Language {
    return super.get("language");
  }
  set language(value: OD3_Language) {
    super.set("language", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Knowledge_Document", OD3_Knowledge_Document);
