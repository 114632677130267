import Parse from "parse";

type OD3_Source = Parse.Object;
type OWPlcDevice = Parse.Object;

export interface OWPlcItemAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  address: string;
  DeviceID?: OWPlcDevice;
  enabled: boolean;
  extraOptions?: any;
  label: string;
  note?: string;
  onChange: boolean;
  OWSource: string;
  source: OD3_Source;
  type: string;
  unit: string;
}

export class OWPlcItem extends Parse.Object<OWPlcItemAttributes> {
  static className: string = "OWPlcItem";

  constructor(data?: Partial<OWPlcItemAttributes>) {
    super("OWPlcItem", data as OWPlcItemAttributes);
  }

  get address(): string {
    return super.get("address");
  }
  set address(value: string) {
    super.set("address", value);
  }
  get DeviceID(): OWPlcDevice | undefined {
    return super.get("DeviceID");
  }
  set DeviceID(value: OWPlcDevice | undefined) {
    super.set("DeviceID", value);
  }
  get enabled(): boolean {
    return super.get("enabled");
  }
  set enabled(value: boolean) {
    super.set("enabled", value);
  }
  get extraOptions(): any | undefined {
    return super.get("extraOptions");
  }
  set extraOptions(value: any | undefined) {
    super.set("extraOptions", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get note(): string | undefined {
    return super.get("note");
  }
  set note(value: string | undefined) {
    super.set("note", value);
  }
  get onChange(): boolean {
    return super.get("onChange");
  }
  set onChange(value: boolean) {
    super.set("onChange", value);
  }
  get OWSource(): string {
    return super.get("OWSource");
  }
  set OWSource(value: string) {
    super.set("OWSource", value);
  }
  get source(): OD3_Source {
    return super.get("source");
  }
  set source(value: OD3_Source) {
    super.set("source", value);
  }
  get type(): string {
    return super.get("type");
  }
  set type(value: string) {
    super.set("type", value);
  }
  get unit(): string {
    return super.get("unit");
  }
  set unit(value: string) {
    super.set("unit", value);
  }
}

Parse.Object.registerSubclass("OWPlcItem", OWPlcItem);
