import React from "react";
import {
  _UserAttributes,
  Kuenne_AkkordEintraege,
  Kuenne_QATest,
  OD3_Source,
} from "../types";
import { useParseQuery } from "parse-hooks";
import dayjs from "dayjs";
import Parse from "parse";

export const useAkkordMetaData = (
  newEntry: Partial<Kuenne_AkkordEintraege>
) => {
  const userQuery = React.useMemo(() => {
    return new Parse.Query(Parse.User<_UserAttributes>);
  }, []);
  const {
    result: users,
    loading: usersLoading,
    error: usersError,
    reload: usersReload,
  } = useParseQuery(userQuery);

  const orderQuery = React.useMemo(() => {
    const q = new Parse.Query(Kuenne_QATest)
      .addDescending("createdAt")
      .limit(1000);
    if (newEntry && newEntry.datum) {
      const dayDatum = dayjs(newEntry.datum?.getTime());

      q.greaterThanOrEqualTo(
        "AENDATETIME",
        dayDatum.startOf("month").subtract(1, "month").toDate()
      );
      q.lessThanOrEqualTo("AENDATETIME", dayDatum.endOf("month").toDate());
    }
    return q;
  }, [newEntry?.datum?.getTime()]);

  const {
    result: orders,
    loading: ordersloading,
    error: orderserror,
    reload: ordersreload,
  } = useParseQuery(orderQuery);

  const sourceQuery = React.useMemo(() => {
    return new Parse.Query(OD3_Source);
  }, []);

  const {
    result: sources,
    loading: sourcesLoading,
    error: sourcesError,
    reload: sourcesReload,
  } = useParseQuery(sourceQuery);

  const machines = React.useMemo(() => {
    const machines = [] as OD3_Source[];
    orders
      .filter((cOrder) => {
        const dayDatum = dayjs(newEntry.datum?.getTime());
        return (
          cOrder.AUFNR === newEntry.auftrag?.AUFNR &&
          cOrder.AENDATETIME!.getTime() > dayDatum.startOf("month").valueOf() &&
          cOrder.AENDATETIME!.getTime() < dayDatum.endOf("month").valueOf()
        );
      })
      .forEach((order) => {
        const source = sources
          .filter((cSource) => !!cSource.tag)
          .find(
            (cSource) =>
              cSource.tag!.toLowerCase() === "kuenne_werk1_m" + order.MASNR
          );
        console.log({ source });
        if (source) {
          if (
            machines.find(
              (cMachine) =>
                cMachine.tag!.toLowerCase() === source.tag!.toLowerCase()
            )
          ) {
            return;
          }
          machines.push(source);
        }
      });
    return machines;
  }, [newEntry?.auftrag?.AUFNR, sources, orders]);
  return {
    orders,
    sources,
    machines,
    users,
    usersLoading,
    usersError,
    usersReload,
    ordersloading,
    orderserror,
    ordersreload,
    sourcesLoading,
    sourcesError,
    sourcesReload,
  };
};
