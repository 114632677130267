import Parse from "parse";

import type { Source } from "./Source";

export interface TenantAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  dataSource?: Source;
  hasDataSource: boolean;
  interactWithOtherTenants: boolean;
  label: string;
  tagPrefix?: string;
}

export class Tenant extends Parse.Object<TenantAttributes> {
  static className: string = "OD3_Tenant";

  constructor(data?: Partial<TenantAttributes>) {
    super("OD3_Tenant", data as TenantAttributes);
  }

  get dataSource(): Source | undefined {
    return super.get("dataSource");
  }
  set dataSource(value: Source | undefined) {
    super.set("dataSource", value);
  }
  get hasDataSource(): boolean {
    return super.get("hasDataSource");
  }
  set hasDataSource(value: boolean) {
    super.set("hasDataSource", value);
  }
  get interactWithOtherTenants(): boolean {
    return super.get("interactWithOtherTenants");
  }
  set interactWithOtherTenants(value: boolean) {
    super.set("interactWithOtherTenants", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get tagPrefix(): string | undefined {
    return super.get("tagPrefix");
  }
  set tagPrefix(value: string | undefined) {
    super.set("tagPrefix", value);
  }
}

Parse.Object.registerSubclass("OD3_Tenant", Tenant);
