import Parse from "parse";

type OD3_Knowledge_Category = Parse.Object;
type OD3_Language = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Knowledge_VideoAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  category: OD3_Knowledge_Category;
  descriptionMarkdown?: string;
  label: string;
  language: OD3_Language;
  tenant: OD3_Tenant;
  thumbnailImage: Parse.File;
  video: Parse.File;
}

export class OD3_Knowledge_Video extends Parse.Object<OD3_Knowledge_VideoAttributes> {
  static className: string = "OD3_Knowledge_Video";

  constructor(data?: Partial<OD3_Knowledge_VideoAttributes>) {
    super("OD3_Knowledge_Video", data as OD3_Knowledge_VideoAttributes);
  }

  get category(): OD3_Knowledge_Category {
    return super.get("category");
  }
  set category(value: OD3_Knowledge_Category) {
    super.set("category", value);
  }
  get descriptionMarkdown(): string | undefined {
    return super.get("descriptionMarkdown");
  }
  set descriptionMarkdown(value: string | undefined) {
    super.set("descriptionMarkdown", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get language(): OD3_Language {
    return super.get("language");
  }
  set language(value: OD3_Language) {
    super.set("language", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get thumbnailImage(): Parse.File {
    return super.get("thumbnailImage");
  }
  set thumbnailImage(value: Parse.File) {
    super.set("thumbnailImage", value);
  }
  get video(): Parse.File {
    return super.get("video");
  }
  set video(value: Parse.File) {
    super.set("video", value);
  }
}

Parse.Object.registerSubclass("OD3_Knowledge_Video", OD3_Knowledge_Video);
