import Parse from "parse";

type OD3_Language = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Knowledge_CategoryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  label: string;
  language: OD3_Language;
  parent?: OD3_Knowledge_Category;
  tenant: OD3_Tenant;
}

export class OD3_Knowledge_Category extends Parse.Object<OD3_Knowledge_CategoryAttributes> {
  static className: string = "OD3_Knowledge_Category";

  constructor(data?: Partial<OD3_Knowledge_CategoryAttributes>) {
    super("OD3_Knowledge_Category", data as OD3_Knowledge_CategoryAttributes);
  }

  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get language(): OD3_Language {
    return super.get("language");
  }
  set language(value: OD3_Language) {
    super.set("language", value);
  }
  get parent(): OD3_Knowledge_Category | undefined {
    return super.get("parent");
  }
  set parent(value: OD3_Knowledge_Category | undefined) {
    super.set("parent", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Knowledge_Category", OD3_Knowledge_Category);
