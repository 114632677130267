import Parse from "parse";

type OD3_Knowledge_Document = Parse.Object;
type OD3_Tenant = Parse.Object;

export interface OD3_Knowledge_DocumentPageAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  annotations: any[];
  document: OD3_Knowledge_Document;
  image: Parse.File;
  label: string;
  page: number;
  tenant: OD3_Tenant;
}

export class OD3_Knowledge_DocumentPage extends Parse.Object<OD3_Knowledge_DocumentPageAttributes> {
  static className: string = "OD3_Knowledge_DocumentPage";

  constructor(data?: Partial<OD3_Knowledge_DocumentPageAttributes>) {
    super("OD3_Knowledge_DocumentPage", data as OD3_Knowledge_DocumentPageAttributes);
  }

  get annotations(): any[] {
    return super.get("annotations");
  }
  set annotations(value: any[]) {
    super.set("annotations", value);
  }
  get document(): OD3_Knowledge_Document {
    return super.get("document");
  }
  set document(value: OD3_Knowledge_Document) {
    super.set("document", value);
  }
  get image(): Parse.File {
    return super.get("image");
  }
  set image(value: Parse.File) {
    super.set("image", value);
  }
  get label(): string {
    return super.get("label");
  }
  set label(value: string) {
    super.set("label", value);
  }
  get page(): number {
    return super.get("page");
  }
  set page(value: number) {
    super.set("page", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("OD3_Knowledge_DocumentPage", OD3_Knowledge_DocumentPage);
