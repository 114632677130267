import Parse from "parse";

export interface OD3_BDE_FormAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  entries?: any[];
  form?: any;
  name?: string;
  RID?: string;
  storeUserInfo: boolean;
}

export class OD3_BDE_Form extends Parse.Object<OD3_BDE_FormAttributes> {
  static className: string = "OD3_BDE_Form";

  constructor(data?: Partial<OD3_BDE_FormAttributes>) {
    super("OD3_BDE_Form", data as OD3_BDE_FormAttributes);
  }

  get entries(): any[] | undefined {
    return super.get("entries");
  }
  set entries(value: any[] | undefined) {
    super.set("entries", value);
  }
  get form(): any | undefined {
    return super.get("form");
  }
  set form(value: any | undefined) {
    super.set("form", value);
  }
  get name(): string | undefined {
    return super.get("name");
  }
  set name(value: string | undefined) {
    super.set("name", value);
  }
  get RID(): string | undefined {
    return super.get("RID");
  }
  set RID(value: string | undefined) {
    super.set("RID", value);
  }
  get storeUserInfo(): boolean {
    return super.get("storeUserInfo");
  }
  set storeUserInfo(value: boolean) {
    super.set("storeUserInfo", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_Form", OD3_BDE_Form);
