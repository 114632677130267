import Parse from "parse";

type OD3_BDE_List = Parse.Object;

export interface OD3_BDE_ListEntryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  key: string;
  list: OD3_BDE_List;
  value: string;
}

export class OD3_BDE_ListEntry extends Parse.Object<OD3_BDE_ListEntryAttributes> {
  static className: string = "OD3_BDE_ListEntry";

  constructor(data?: Partial<OD3_BDE_ListEntryAttributes>) {
    super("OD3_BDE_ListEntry", data as OD3_BDE_ListEntryAttributes);
  }

  get key(): string {
    return super.get("key");
  }
  set key(value: string) {
    super.set("key", value);
  }
  get list(): OD3_BDE_List {
    return super.get("list");
  }
  set list(value: OD3_BDE_List) {
    super.set("list", value);
  }
  get value(): string {
    return super.get("value");
  }
  set value(value: string) {
    super.set("value", value);
  }
}

Parse.Object.registerSubclass("OD3_BDE_ListEntry", OD3_BDE_ListEntry);
